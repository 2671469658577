import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout } from "../components";

const Terms = () => {
  return <PageLayout title="Terms and Conditions" flourish={1}></PageLayout>;
};

export default Terms;
